import { Nav, Navbar, NavLink, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../Images/SmallLogo.png";

const NavigationBar = () => {
	return (
		<Navbar collapseOnSelect expand="sm" bg="light" data-bs-theme="light">
			<Navbar.Toggle aria-controls="responsive-navbar-nav" />
			<Navbar.Collapse>
				<Nav>
					<Navbar.Brand eventkey={process.env.REACT_APP_APP_NAME + " Home"} 
						href="https://proofpeak.com" style={{marginLeft: '20px'}}>
							 <img
								alt=""
								src={Logo}
								width="30"
								height="30"
								className="d-inline-block align-top"
								/>{' '}
							{process.env.REACT_APP_APP_NAME}</Navbar.Brand>
					<NavLink eventKey="Portfolio" as={Link} to="/">Portfolio</NavLink>
					<NavLink eventKey="Organizations" as={Link} to="/organization/list">Organizations</NavLink> 
					<NavDropdown title="Profile" id="basic-nav-dropdown">
						<NavDropdown.Item eventKey="Profile" href="/profile">Details</NavDropdown.Item>
						<NavDropdown.Item eventKey="ChangePassword" href="/profile/password">Change Password</NavDropdown.Item>
					</NavDropdown>
					<NavLink eventKey="Logout" as={Link} to="/logout">Logout</NavLink>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
};

export default NavigationBar;