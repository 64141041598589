import { useState } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';


const FooterLinks = () => {
    return (
        <>
            <a href="/login" className="link-primary">Login</a>
            {"\u00A0|\u00A0"}
            <a href="/register" className="link-primary">Register</a>
        </>
    );
}

const ResendRegistrationVerificationEmail = () => {

    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [buttonClicked, setButtonClicked] = useState(false);
    const [alertVariant, setAlertVariant] = useState("danger");

    let handleSubmit = async (e) => {
        e.preventDefault();

        try 
        {
            setButtonClicked(true);
            let response = await axios.get(process.env.REACT_APP_BASE_AUTH_API_URL + '/resendConfirmationEmail', {
                params: {
                    email: email
                }
            } );
            setAlertVariant("primary");               
            setMessage(response.data.message);
            setEmail("");
        }
        catch (error)
        {
            console.log(error)
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        } 
        finally 
        {
            window.scrollTo(0, 0);
            setButtonClicked(false);
        }
    };

    return (
        <HelmetProvider>
            <Helmet>
                <title>{ process.env.REACT_APP_APP_NAME } - Resend Registration Email</title>
            </Helmet>
            <div className="d-flex align-items-center justify-content-center p-3">
                <table>
                    <tbody>
                        <tr>
                            <td className="d-flex align-items-center justify-content-center">
                                <h3>Resend Registration Email</h3>
                            </td>
                        </tr>
                        <tr>
                            <td className="d-flex align-items-center justify-content-center Outer">    
                                {message ? <Alert key="messageAlert" variant={alertVariant}>{message}</Alert> : null}
                            </td>
                        </tr>
                        <tr>
                            <td className="d-flex align-items-center justify-content-center">
                                <Form onSubmit={handleSubmit} className='Form'>
                                    <Form.Group className="mb-3" controlId="formEmail">
                                        <Form.Label>Username (email address)</Form.Label>
                                        <Form.Control type="email" placeholder="Enter Username"
                                        value={email}
                                        name="email" 
                                        onChange={(e) => setEmail(e.target.value)} 
                                        required />
                                    </Form.Group>
                                    <Button variant="primary" type="submit" hidden={buttonClicked}>
                                        Resend Registration Email
                                    </Button>
                                    <Button variant="primary" disabled hidden={!buttonClicked}>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        Loading...
                                    </Button>
                                </Form>
                            </td>
                        </tr>
                        <tr>
                            <td className="d-flex align-items-center justify-content-center">
                                { FooterLinks() }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>       
        </HelmetProvider>
    );
}

export default ResendRegistrationVerificationEmail;