import { useState, useEffect, useRef } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import ReactCountryDropdown from "react-country-dropdown";
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import TelephoneNumber from "../../TelephoneNumber";

function isEmpty(value) {
	return (value == null || (typeof value === "string" && value.trim().length === 0));
}

const CreateOrganization = () => { 

	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [addressLine1, setAddressLine1] = useState("");
	const [addressLine2, setAddressLine2] = useState("");
	const [city, setCity] = useState("");
	const [stateProvince, setStateProvince] = useState("");
	const [postalCode, setPostalCode] = useState("");
	const [country, setCountry] = useState("US");
	const [telephoneNumber, setTelephoneNumber] = useState("");
	const [affiliationApprovalRequired, setAffiliationApprovalRequired] = useState(true);
	const [message, setMessage] = useState("");
    const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
	const [deleteButtonClicked, setDeleteButtonClicked] = useState(false);
    const [alertVariant, setAlertVariant] = useState("danger");
	const [title, setTitle] = useState("Create Organization");
	const [submitButtonText, setSubmitButtonText] = useState("Create Organization");
	const [displayForm, setDisplayForm] = useState(true);
	const [displayDeleteButton, setDisplayDeleteButton] = useState(false);
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

	const organizationUuid = useRef();
    
	const affiliationApprovalRequiredHandler = () => {
		setAffiliationApprovalRequired(!affiliationApprovalRequired)
	}

	const handleCloseDeleteConfirmation = () => setShowDeleteConfirmation(false);
  	const handleShowDeleteConfirmation = () => setShowDeleteConfirmation(true);

	useEffect(() => {
		const getOrganizationDetails = async () => {
			try 
			{
				let response = await axios.get(process.env.REACT_APP_BASE_API_URL + "/organization/" + organizationUuid.current, { withCredentials: true });
				setName(response.data.name);
				setDescription(response.data.description);
				setAddressLine1(response.data.addressLine1);
				setAddressLine2(response.data.addressLine2);
				setCity(response.data.city);
				setStateProvince(response.data.stateProvince);
				setPostalCode(response.data.postalCode);
				setTelephoneNumber(response.data.telephoneNumber);
				setAffiliationApprovalRequired(response.data.affiliationApprovalRequired);
			}
			catch (error)
			{
				console.log(error)
				setAlertVariant("danger");
				if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
					setMessage(error.response.data.message)
				} else {
					setMessage("Unknown Error");
				}
			}
		}
		if (!displayForm)
		{
			return
		}
		const queryParameters = new URLSearchParams(window.location.search);
		if (!isEmpty(queryParameters.get("organizationUuid")))
		{
			organizationUuid.current = queryParameters.get("organizationUuid");
		}
		if (!isEmpty(organizationUuid.current))
		{
			setTitle("Edit Organization")
			setSubmitButtonText("Update Organization");
			getOrganizationDetails();
			setDisplayDeleteButton(true);
		}
	}, [displayForm, organizationUuid]);

	let handleSubmit = async (e) => {
        e.preventDefault();

        setSubmitButtonClicked(true);

        const organizationInfo = {
            name: name,
			description: description,
            addressLine1: addressLine1,
            addressLine2: addressLine2,
            city: city,
            stateProvince: stateProvince,
            postalCode: postalCode,
            country: country,
			telephoneNumber: telephoneNumber,
			affiliationApprovalRequired: affiliationApprovalRequired
        };

        try 
        {
			
            let response;
			if (!isEmpty(organizationUuid.current))
			{
				response = await axios.put(process.env.REACT_APP_BASE_API_URL + "/organization/update/" + organizationUuid.current, organizationInfo, { withCredentials: true });
			}
			else 
			{
				response = await axios.post(process.env.REACT_APP_BASE_API_URL + "/organization/create", organizationInfo, { withCredentials: true });
				setTitle("Edit Organization");
				setSubmitButtonText("Update Organization");
				organizationUuid.current = response.data.uuid;
				setDisplayDeleteButton(true);
			}
            setAlertVariant("primary");               
            setMessage(response.data.message);
        }
        catch (error)
        {
            console.log(error);
			setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        } 
        finally 
        {
            window.scrollTo(0, 0);
            setSubmitButtonClicked(false);
        }
    };

	let handleDelete = async (e) => {
        e.preventDefault();

        setDeleteButtonClicked(true);

        try 
        {
			let response = await axios.delete(process.env.REACT_APP_BASE_API_URL + "/organization/delete/" + organizationUuid.current, { withCredentials: true });
			setName("");
			setDescription("");
			setAddressLine1("");
			setAddressLine2("");
			setCity("");
			setStateProvince("");
			setPostalCode("");
			setTelephoneNumber("");
            setAlertVariant("primary");               
            setMessage(response.data.message);
			organizationUuid.current = null;
			setDisplayForm(true);
			setDisplayDeleteButton(false);
			setTitle("Create Organization");
			setSubmitButtonText("Create Organization")
			setShowDeleteConfirmation(false);
        }
        catch (error)
        {
            console.log(error);
			setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        } 
        finally 
        {
            window.scrollTo(0, 0);
            setDeleteButtonClicked(false);
        }
    };

	return (
		<HelmetProvider>
			<Helmet>
					<title>{ process.env.REACT_APP_APP_NAME } - {title}</title>
			</Helmet> 
			{ name && !organizationUuid.current &&
				<div className="p-2">
					<h6>{name}{' > '}Create</h6>
				</div>
			}
			{ name && organizationUuid.current &&
				<div className="p-2">
					<h6><a href={"/organization?organizationUuid=" + organizationUuid.current}>{name}</a>{' > '}Edit</h6>
				</div>
			}
			<div className="d-flex align-items-center justify-content-center p-3">
				<div className="Outer">
					<table>
						<tbody>
							<tr>
								<td className="d-flex align-items-center justify-content-center">
									<h3>{title}</h3>
								</td>
							</tr>
							<tr>
								<td className="d-flex align-items-center justify-content-center Outer">    
									{message ? <Alert key="messageAlert" variant={alertVariant}>{message}</Alert> : null}
								</td>
							</tr>
							{displayForm &&
								<tr>
									<td className='d-flex align-items-center justify-content-center'>
										<Form onSubmit={handleSubmit} className='Form Margin-Bottom'>
											<Form.Group className="mb-3" controlId="formName">
												<Form.Label>Name *</Form.Label>
												<Form.Control type="text" placeholder="Enter Organization Name"
													value={name}
													name="name" 
													onChange={(e) => setName(e.target.value)} 
													required />
											</Form.Group>
											<Form.Group className="mb-3" controlId="formDescription">
												<Form.Label>Description</Form.Label>
												<Form.Control as="textarea" placeholder="Enter Description"
													value={description}
													name="description" 
													rows={3}
													onChange={(e) => setDescription(e.target.value)} />
											</Form.Group>
											<Form.Group className="mb-3" controlId="formAddressLine1">
												<Form.Label>Address Line 1 *</Form.Label>
												<Form.Control type="text" placeholder="Enter Address Line 1"
													value={addressLine1}
													name="addressLine1" 
													onChange={(e) => setAddressLine1(e.target.value)} 
													required />
											</Form.Group>
											<Form.Group className="mb-3" controlId="formAddressLine2">
												<Form.Label>Address Line 2</Form.Label>
												<Form.Control type="text" placeholder="Enter Address Line 2"
													value={addressLine2}
													name="addressLine2" 
													onChange={(e) => setAddressLine2(e.target.value)} />
											</Form.Group>
											<Form.Group className="mb-3" controlId="formCity">
												<Form.Label>City *</Form.Label>
												<Form.Control type="text" placeholder="Enter City"
													value={city}
													name="city" 
													onChange={(e) => setCity(e.target.value)} 
													required />
											</Form.Group>
											<Form.Group className="mb-3" controlId="formStateProvince">
												<Form.Label>State/Province *</Form.Label>
												<Form.Control type="text" placeholder="Enter State/Province"
													value={stateProvince}
													name="stateProvince" 
													onChange={(e) => setStateProvince(e.target.value)} 
													required />
											</Form.Group>
											<Form.Group className="mb-3" controlId="formPostalCode">
												<Form.Label>Postal Code *</Form.Label>
												<Form.Control type="text" placeholder="Enter Postal Code"
													value={postalCode}
													name="postalCode" 
													onChange={(e) => setPostalCode(e.target.value)} 
													required />
											</Form.Group>
											<Form.Group className="mb-3" controlId="formCountry">
												<Form.Label>Country *</Form.Label><br />
												<ReactCountryDropdown
													defaultCountry="US"
													onSelect={(e) => setCountry(e.name)}
													required />
											</Form.Group>
											<TelephoneNumber telephoneNumber={telephoneNumber} setTelephoneNumber={setTelephoneNumber} />
											<Form.Group className="mb-3" controlId="formAffiliationApprovalRequired">
												<Form.Label>Approval Is Required For Affiliation *</Form.Label>
												<Form.Check type="switch" 
													name="affiliationApprovalRequired" 
													onChange={affiliationApprovalRequiredHandler}
													checked={affiliationApprovalRequired} />
											</Form.Group>
											<p>
												{ '* Required' }
											</p>
											<Button variant="primary" type="submit" hidden={submitButtonClicked}>
												{submitButtonText}
											</Button>
											<Button variant="primary" disabled hidden={!submitButtonClicked}>
												<Spinner
													as="span"
													animation="grow"
													size="sm"
													role="status"
													aria-hidden="true"
												/>
												Loading...
											</Button>
										</Form>
									</td>
								</tr>
							}
							{ displayDeleteButton &&
								<tr>
									<td>
										<div className="Form">
											Caution, this cannot be undone!<br />
											<Button variant="danger" onClick={handleShowDeleteConfirmation} type="submit" hidden={deleteButtonClicked}>
												Delete Organization
											</Button>
											<Button variant="danger" disabled hidden={!deleteButtonClicked}>
												<Spinner
													as="span"
													animation="grow"
													size="sm"
													role="status"
													aria-hidden="true"
												/>
												Deleting...
											</Button>
											<Modal show={showDeleteConfirmation} onHide={handleCloseDeleteConfirmation}>
												<Modal.Header closeButton>
													<Modal.Title>Confirm Organization Deletion</Modal.Title>
												</Modal.Header>
												<Modal.Body>Are you certain you want to delete the organization ({name})? This cannot be undone!</Modal.Body>
												<Modal.Footer>
												<Button variant="secondary" onClick={handleCloseDeleteConfirmation}>
													Cancel
												</Button>
												<Button variant="danger" onClick={handleDelete}>
													Delete Organization
												</Button>
												</Modal.Footer>
											</Modal>
										</div>
									</td>
								</tr>
							}
						</tbody>
					</table>
				</div>
			</div>

		</HelmetProvider>
	);
}

export default CreateOrganization;