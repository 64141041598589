import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Form } from "react-bootstrap";
import "./RequirementTypes.css";

const RequirementTypeSelect = ({requirementTypeId, setRequirementTypeId}) => {

    const [requirementTypeOptions, setRequirementTypeOptions] = useState([]);

    useEffect(() => {
		fetchData();
	}, []);

    const fetchData = async (value) => {
        try
        {
            let requirementTypesResult = await axios.get(process.env.REACT_APP_BASE_API_URL + '/credential/requirement/types', { withCredentials: true } );
            const requirementTypesArray = [];
            requirementTypesResult.data.forEach((value) => {
                let key = value.name;
                if (value.description)
                {
                    key = key + " - " + value.description;
                }
                requirementTypesArray.push({
                    key: key,
                    value: value.id,
                });
            });
            setRequirementTypeOptions([{key: 'Select Requirement Type', value: ''}, ...requirementTypesArray]);
        }
        catch (error)
        {
            console.log(error);
        }
    };

	return (
        <Form.Group className="mb-3" controlId="formRequirementType">
            <Form.Label>Type *</Form.Label>
            <Form.Control as="select"
                value={requirementTypeId}
                name="requirementType" 
                onChange={(e) => setRequirementTypeId(e.target.value)} 
                className="requirement-types-list"
                required>
                {requirementTypeOptions.map((option) => {
                    return (
                        <option key={option.value} value={option.value}>
                            {option.key}
                        </option>
                    );
                })}
            </Form.Control>
        </Form.Group>
	);
};

export default RequirementTypeSelect;
