import { useState, useEffect, useRef } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
import { Button } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

const CredentialVersion = () => {

    const [alertVariant, setAlertVariant] = useState("danger");
    const [message, setMessage] = useState("");
    const [organization, setOrganization] = useState({});
    const [credential, setCredential] = useState({});
    const [credentialVersion, setCredentialVersion] = useState({});
    const [dateButtonClicked, setDateButtonClicked] = useState(false);
    const [deprecationDate, setDeprecationDate] = useState();
    const [update, setUpdate] = useState(false);
    
    const organizationUuid = useRef();
    const credentialVersionUuid = useRef();

    useEffect(() => {
        fetchData();
        setUpdate(false);
    }, [update]);

    const fetchData = async () => {
        try
        {
            const queryParameters = new URLSearchParams(window.location.search);
		    organizationUuid.current = queryParameters.get("organizationUuid");
            credentialVersionUuid.current = queryParameters.get("credentialVersionUuid");
            let organizationResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/organization/" + organizationUuid.current, 
                { withCredentials: true });
            setOrganization(organizationResponse.data);
            let credentialVersionResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/credential/version/" + credentialVersionUuid.current, 
                { withCredentials: true });
            setCredentialVersion(credentialVersionResponse.data);
            setDeprecationDate(credentialVersionResponse.data.deprecationDate.split('T')[0]);
            let credentialResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/credential/" + credentialVersionResponse.data.credentialUuid, 
                { withCredentials: true });
            setCredential(credentialResponse.data);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        }
    };

    const formatDate = (dateInputString) => {
        if (!dateInputString)
        {
            return;
        }
        const date = Date.parse(dateInputString);
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZone: 'UTC'
        };
        return Intl.DateTimeFormat(undefined, options).format(date);
	}

    let handleUpdateDeprecationDate = async (e) => {
        e.preventDefault();
        setDateButtonClicked(true);
        const version = {
            deprecationDate: deprecationDate
        };

        try 
        {
            
            let response = await axios.put(process.env.REACT_APP_BASE_API_URL + '/credential/version/update/' + credentialVersionUuid.current, 
                version, { withCredentials: true } );  
            setMessage(response.data.message);
            setAlertVariant("primary");  
            setUpdate(true);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger"); 
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        } 
        finally 
        {
            window.scrollTo(0, 0);
            setDateButtonClicked(false);
        }
    };

	return (
		<HelmetProvider>
            <Helmet>
                    <title>{ process.env.REACT_APP_APP_NAME } - Credential Version</title>
            </Helmet>
            <div className="p-2">
                <h6>
                    <a href={"/organization?organizationUuid=" + organization.uuid }>{organization.name}</a>{' > '} 
                    <a href={"/credential/list?organizationUuid=" + organization.uuid }>Credentials</a> {' > '} 
                    <a href={"/credential/edit?organizationUuid=" + organization.uuid + "&credentialUuid=" + credential.uuid}>{credential.name}</a> {' > '} 
                    {credentialVersion.versionName}
                </h6>
            </div>
            <div className="p-3 Center-Outer">
                <div className="Outer">
                    <h3 className="Center-Text Margin-Bottom">Credential Version</h3>
                    {message ? <Alert key="messageAlert" variant={alertVariant}>{message}</Alert> : null}
                    <div className="Details Margin-Bottom">
                        <table>
                            <tbody>
                                <tr>
                                    <td>Name: </td>
                                    <td>{credentialVersion.versionName}</td>
                                </tr>
                                {credentialVersion.description &&
                                    <tr>
                                        <td>Description: </td>
                                        <td>{credentialVersion.description}</td>
                                    </tr>
                                }
                                <tr>
                                    <td>Type: </td>
                                    <td>{credentialVersion.type}</td>
                                </tr>
                                <tr>
                                    <td>Expiration Type: </td>
                                    <td>
                                        { credentialVersion.credentialExpirationType &&
                                            <>
                                                {credentialVersion.credentialExpirationType.name} - {credentialVersion.credentialExpirationType.description}
                                            </>
                                        }
                                    </td>
                                </tr>
                                { credentialVersion.expiryDaysFromCompletion > 0 &&
                                    <tr>
                                        <td>Expiry Days After Completion: </td>
                                        <td>{credentialVersion.expiryDaysFromCompletion}</td>
                                    </tr>
                                }
                                { credentialVersion.expiryDate !== "0001-01-01T00:00:00" &&
                                    <tr>
                                        <td>Expiry Date: </td>
                                        <td>{formatDate(credentialVersion.expiryDate)}</td>
                                    </tr>
                                }
                                <tr>
                                    <td>Published By: </td>
                                    <td>{credentialVersion.publishedBySurname}, {credentialVersion.publishedByGivenName}</td>
                                </tr>
                                <tr>
                                    <td>Deprecation Date: </td>
                                    <td>{formatDate(credentialVersion.deprecationDate)}</td>
                                </tr>
                                <tr>
                                    <td>NAICS Classification: </td>
                                    <td>
                                        { credentialVersion.naicsCode &&
                                            <>
                                                {credentialVersion.naicsCode.definition}
                                            </>
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="Form Margin-Bottom">
                        <h5>Deprecation Date</h5>
                        <div>Must be today or later.</div>
                        <Form onSubmit={handleUpdateDeprecationDate}>
                        <Form.Group className="mb-3" controlId="formDate">
                            <Form.Control type="date" 
                                defaultValue={deprecationDate}
                                name="date" 
                                onChange={(e) => setDeprecationDate(e.target.value)} 
                                required />
                            </Form.Group>
                            <Button variant="primary" type="submit" hidden={dateButtonClicked}>
                                Update Deprecation Date
                            </Button>
                            <Button variant="primary" disabled hidden={!dateButtonClicked}>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                Loading...
                            </Button>
                        </Form>
                    </div>
                    <h5 className="Margin-Bottom">Requirements</h5>
                    { credentialVersion.requirements && credentialVersion.requirements.map((requirement) => {
                            return(
                                <div className="list-group" key={requirement.credentialRequirementUuid}>
                                    <div className="list-group-item list-group-item-action flex-column align-items-start">
                                        <div className="d-flex w-100 justify-content-between">
                                            <h5 className="mb-1">{requirement.name}</h5>
                                            <small>
                                                {requirement.isSystemRequirement ? "System Requirement " : "Organization Requirement "}
                                                ({requirement.requirementType.name})
                                            </small>
                                        </div>
                                        {requirement.description &&
                                            <div style={{ whiteSpace: 'pre-wrap' }}>
                                                <p className="mb-1">{requirement.description}</p>
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                    })}
                </div> 
            </div> 
        </HelmetProvider>
	);
};

export default CredentialVersion;
