import { useEffect, useState, useRef } from 'react'; 
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import AppLayout from './Layout/AppLayout';
import Portfolio from './Pages';
import Login from './Pages/Authentication/Login';
import Register from './Pages/Authentication/Register';
import VerifyRegistration from './Pages/Authentication/VerifyRegistration';
import Logout from './Pages/Authentication/Logout'
import Version from './Pages/Version'
import Spinner from 'react-bootstrap/Spinner';
import PasswordResetRequest from './Pages/Authentication/PasswordResetRequest';
import PasswordResetVerify from './Pages/Authentication/PasswordResetVerify';
import axios from 'axios';
import ResendRegistrationVerificationEmail from './Pages/Authentication/ResendRegistrationVerificationEmail';
import Organization from './Pages/Organization';
import EditOrganization from './Pages/Organization/EditOrganization';
import OrganizationDetails from './Pages/Organization/OrganizationDetails';
import Profile from './Pages/Profile';
import ChangePassword from './Pages/Authentication/ChangePassword';
import Requirement from './Pages/Requirement';
import EditRequirement from './Pages/Requirement/EditRequirement';
import Credential from './Pages/Credential';
import EditCredential from './Pages/Credential/EditCredential';
import CredentialRequirement from './Pages/Credential/Requirement';
import CredentialVersion from './Pages/Credential/CredentialVersion';

window.authenticated = false;

function App() {

  const [loading, setLoading] = useState(true);
  const authCheckedRef = useRef(false);

  useEffect(() => {
    if (authCheckedRef.current) return;
    authCheckedRef.current = true;
    const checkAuth = async () => {
        try 
        {
          await axios.get(process.env.REACT_APP_BASE_AUTH_API_URL + "/verify", { withCredentials: true })
          window.authenticated = true;
        }
        catch (error)
        {
          console.log(error);
          window.authenticated = false;
        }
        finally
        {
          setLoading(false);
        }
    }
    checkAuth();
  }, []);
  
  if (loading)
  {
    return (
      <div className="d-flex align-items-center justify-content-center p-3">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }
  else
  {
    return (
      <Router>
        <Routes>
            <Route path='/register' element={<Register/>} />
            <Route path='/login' element={<Login/>} />
            <Route path='/register/verify' element={<VerifyRegistration/>} />
            <Route path='/register/resend' element={<ResendRegistrationVerificationEmail/>} />
            <Route path='/passwordreset' element={<PasswordResetRequest/>} />
            <Route path='/passwordreset/verify' element={<PasswordResetVerify/>} />
            <Route path='/version' element={<Version/>} />
            <Route path='/logout' element={
              <ProtectedRoute>
                <Logout/>
              </ProtectedRoute>
            } />
            <Route path='/' element={
              <ProtectedRoute>
                <AppLayout />
              </ProtectedRoute>
            }>
                <Route index element={<Portfolio />} />
                <Route path='/organization/list' element={<Organization/>} />
                <Route path='/organization' element={<OrganizationDetails/>} />
                <Route path='/organization/edit' element={<EditOrganization/>} />
                <Route path='/profile' element={<Profile/>} />
                <Route path='/profile/password' element={<ChangePassword/>} />
                <Route path='/credential/list' element={<Credential />} />
                <Route path='/credential/edit' element={<EditCredential />} />
                <Route path='/credential/requirement' element={<CredentialRequirement />} />
                <Route path='/credential/version' element={<CredentialVersion />} />
                <Route path='/requirement/list' element={<Requirement/>} />
                <Route path='/requirement/edit' element={<EditRequirement/>} />
            </Route>
        </Routes>
      </Router>
    );
  }
}

export default App;