import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Form } from "react-bootstrap";
import "./RequirementTypes.css";
import OrganizationList from "./OrganizationList";

const CredentialSelect = ({requirementTypeId, credentialUuid, setCredentialUuid, organizationInput, 
    setOrganizationInput, credentials, setCredentials}) => {

    const [organizationSearchResults, setOrganizationSearchResults] = useState([]);
    const [showFields, setShowFields] = useState(false);

    const searchOrgData = async (value) => {
        try
        {
            if (!value)
            {
                return
            }
            let results = await axios.get(process.env.REACT_APP_BASE_API_URL + "/organization/search", 
                { 
                    params: {
                        searchParameter: value
                    },
                    withCredentials: true 
                });

            setOrganizationSearchResults(results.data)
        }
        catch (error)
        {
            console.log(error);
        }
    };

    const handleOrgChange = (value) => {
        setOrganizationInput(value);
        setCredentials([]);
        setCredentialUuid("");
        if (value === "")
        {
            setOrganizationSearchResults([]);
        }
        else
        {
            searchOrgData(value);
        }
    }

    useEffect(() => {
        if (Number(requirementTypeId) === 2)
        {
            setShowFields(true);
        }
        else
        {
            setShowFields(false);
        }
    }, [requirementTypeId]);

    return (
        <div>
            { showFields &&
                <>
                    <Form.Group className="mb-3" controlId="formOrganization">
                        <Form.Label>Organization *</Form.Label>
                        <Form.Control type="text" placeholder="Type To Search Organizations"
                            autoComplete="off"
                            value={organizationInput}
                            name="organization" 
                            onChange={(e) => handleOrgChange(e.target.value)} 
                            required />
                        <OrganizationList 
                            organizationSearchResults={organizationSearchResults} 
                            setOrganizationInput={setOrganizationInput} 
                            setOrganizationSearchResults={setOrganizationSearchResults}
                            setCredentials={setCredentials}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formCredential">
                        <Form.Label>Credential *</Form.Label>
                        <Form.Control as="select"
                            value={credentialUuid}
                            name="credential" 
                            onChange={(e) => setCredentialUuid(e.target.value)} 
                            required>
                            {credentials.map((option) => {
                                return (
                                    <option key={option.uuid} value={option.uuid}>
                                        {option.name}
                                    </option>
                                );
                            })}
                        </Form.Control>
                    </Form.Group>
                </>
            }
        </div>
    );
};

export default CredentialSelect;
