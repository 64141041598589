import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Navigate } from 'react-router-dom';
import { useState } from "react";
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';

const Login = () => {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");
    const [buttonClicked, setButtonClicked] = useState(false);

    function refreshPage() {
        window.location.reload(false);
    }

    let handleSubmit = async (e) => {
        window.scrollTo(0, 0);
        e.preventDefault();
        if (username === "" || password === "")
        {
            setMessage("You must include a username and password.");
            return;
        }
        setButtonClicked(true);

        const credentials = {
            username: username,
            password: password
        };

        try 
        {
            await axios.post(process.env.REACT_APP_BASE_AUTH_API_URL + '/login', credentials, { withCredentials: true });
            setButtonClicked(false);
            setUsername("");
            setPassword("");          
            refreshPage();
        }
        catch (error)
        {
            console.log(error)
            setButtonClicked(false);
            if (error.response && (error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message);
            } else {
                setButtonClicked(false);
                setMessage("Please enter a valid username and password.");
            }
        }
        finally
        {
            window.scrollTo(0, 0);
        }
    };

    if (window.authenticated) {
        return <Navigate to="/" />;
    }
    else {
        return (
            <HelmetProvider>
                <Helmet>
                     <title>{ process.env.REACT_APP_APP_NAME } - Login</title>
                </Helmet>
                <div className="d-flex align-items-center justify-content-center p-3">
                    <table>
                        <tbody>
                            <tr>
                                <td className="d-flex align-items-center justify-content-center">
                                    <h3>Login</h3>
                                </td>
                            </tr>
                            <tr>
                                <td className="d-flex align-items-center justify-content-center Outer">    
                                    {message ? <Alert key="danger" variant="danger">{message}</Alert> : null}
                                </td>
                            </tr>
                            <tr>
                                <td className='d-flex align-items-center justify-content-center'>
                                    <Form onSubmit={handleSubmit} className='Form'>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Username (email address)</Form.Label>
                                            <Form.Control type="email" placeholder="Enter Username"
                                            value={username}
                                            name="username" 
                                            onChange={(e) => setUsername(e.target.value)} 
                                            
                                            required />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control type="password" placeholder="Enter Password" 
                                                name="password" 
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)} 
                                                required />
                                        </Form.Group>
                                        <Button variant="primary" type="submit" hidden={buttonClicked}>
                                            Login
                                        </Button>
                                        <Button variant="primary" disabled hidden={!buttonClicked}>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            Loading...
                                        </Button>
                                    </Form>
                                        
                                </td>
                            </tr>
                            <tr>
                                <td className="d-flex align-items-center justify-content-center">
                                    <span>
                                        <a href="/register" className="link-primary">Register</a>
                                    </span>
                                    <span>
                                        {"\u00A0|\u00A0"}
                                        <a href="/passwordreset" className="link-primary">Forgot Password</a>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </HelmetProvider>
        );
    }
}

export default Login;