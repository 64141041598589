import { Helmet, HelmetProvider } from 'react-helmet-async';

const Portfolio = () => {    
	return (
		<HelmetProvider>
			<Helmet>
					<title>{ process.env.REACT_APP_APP_NAME } - Portfolio</title>
			</Helmet>
			<div>
				Portfolio
			</div>       
		</HelmetProvider>
	);
}

export default Portfolio;