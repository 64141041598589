import { useState, useEffect, useRef } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';
import CredentialExpirationTypeSelect from "./CredentialExpirationTypeSelect";
import Modal from 'react-bootstrap/Modal';
import NaicsSelect from "./NaicsSelect";
import DateOfCompletion from "./DateOfCompletion";
import DefinedExpiration from "./DefinedExpiration";
import EvaluatorRequirementSelect from "./EvaluatorRequirementSelect";

const EditCredential = () => {

    const [title, setTitle] = useState("Create Credential");
    const [updateButtonText, setUpdateButtonText] = useState("Create Credential");
    const [alertVariant, setAlertVariant] = useState("danger");
    const [message, setMessage] = useState("");
    const [updateButtonClicked, setUpdateButtonClicked] = useState(false);
    const [deleteButtonClicked, setDeleteButtonClicked] = useState(false);
    const [versionButtonClicked, setVersionButtonClicked] = useState(false);
    const [displayActions, setDisplayActions] = useState(false);
    const [credentialUuid, setCredentialUuid] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [type, setType] = useState("");
    const [credentialExpirationTypeId, setCredentialExpirationTypeId] = useState();
    const [naicsCodeId, setNaicsCodeId] = useState();
    const [evaluatorRequirementId, setEvaluatorRequirementId] = useState(0);
    const [evaluatorRequirementDescription, setEvaluatorRequirementDescription] = useState("");
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showVersionConfirmation, setShowVersionConfirmation] = useState(false);
    const [displayForm, setDisplayForm] = useState(true);
    const [publicOrg, setPublicOrg] = useState(true);
    const [organization, setOrganization] = useState("");
    const [expirationDays, setExpirationDays] = useState(1);
    let tomorrow = new Date();
    tomorrow.setHours(12, 0, 0, 0);
    tomorrow.setDate(tomorrow.getDate() + 1);
    let tomorrowString = tomorrow.toISOString().split('T')[0];
    const [expirationDate, setExpirationDate] = useState(tomorrowString);
    const [versions, setVersions] = useState([]);
    const [versionName, setVersionName] = useState("");
    const [versionDate, setVersionDate] = useState(tomorrowString);

    const organizationUuid = useRef();
    const createdNewCredential = useRef(false);
    const deleted = useRef(false);

    const handleCloseDeleteConfirmation = () => setShowDeleteConfirmation(false); 
  	const handleShowDeleteConfirmation = () => setShowDeleteConfirmation(true);
    const handleCloseVersionConfirmation = () => setShowVersionConfirmation(false);
  	const handleShowVersionConfirmation = () => {
        if (!versionName)
        {
            setAlertVariant("danger");
            setMessage("You must provide a version name.");
            window.scrollTo(0, 0);
            return;
        }
        setShowVersionConfirmation(true);
    };

    const publicHandler = () => {
		setPublicOrg(!publicOrg)
	}

    const formatDate = (dateInputString) => {
        const date = Date.parse(dateInputString);
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          };
        return Intl.DateTimeFormat(undefined, options).format(date);
	}

    useEffect(() => {
        let fetchData = async () => {
            const queryParameters = new URLSearchParams(window.location.search);
            organizationUuid.current = queryParameters.get("organizationUuid");
            let organizationResult = await axios.get(process.env.REACT_APP_BASE_API_URL + '/organization/' + organizationUuid.current, 
                { withCredentials: true } );
            setOrganization(organizationResult.data);
            if (!createdNewCredential.current)
            {
                setCredentialUuid(queryParameters.get("credentialUuid"));
            }
            if (credentialUuid && !deleted.current)
            {
                try
                {
                    let result = await axios.get(process.env.REACT_APP_BASE_API_URL + '/credential/' + credentialUuid, 
                        { withCredentials: true, includeRequirements: true } );
                    setTitle("Edit Credential");
                    setUpdateButtonText("Update Credential");
                    setName(result.data.name);
                    setDescription(result.data.description);
                    setCredentialExpirationTypeId(result.data.credentialExpirationTypeId);
                    setDisplayActions(true);
                    setType(result.data.type);
                    setNaicsCodeId(result.data.naicsCodeId);
                    setPublicOrg(result.data.public);
                    setEvaluatorRequirementId(result.data.evaluatorRequirement.id);
                    let expiryDaysFromCompletion = 1;
                    if (Number(result.data.expiryDaysFromCompletion) > 0)
                    {
                        expiryDaysFromCompletion = result.data.expiryDaysFromCompletion;
                    }
                    setExpirationDays(expiryDaysFromCompletion);
                    let date = result.data.expiryDate.split('T')[0];
                    if (date !== "0001-01-01")
                    {
                        setExpirationDate(date);
                    }
                    let versionsResult = await axios.get(process.env.REACT_APP_BASE_API_URL + '/credential/versions/' + credentialUuid, 
                        { withCredentials: true } );
                    setVersions(versionsResult.data);
                }
                catch (error)
                {
                    console.log(error)
                    if (error.response.status === 404)
                    {
                        return
                    }
                    setAlertVariant("danger");
                    if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                        setMessage(error.response.data.message)
                    } else {
                        setMessage("Unknown Error");
                    }
                }
            }
        };
		fetchData();
	}, [credentialUuid, displayForm]);

    let handleUpdate = async (e) => {
        e.preventDefault();

        setUpdateButtonClicked(true);

        const credential = {
            name: name,
            description: description,
            type: type,
            credentialExpirationTypeId: credentialExpirationTypeId,
            public: publicOrg,
            organizationUuid: organizationUuid.current,
            naicsCodeId: naicsCodeId,
            expiryDaysFromCompletion: expirationDays,
            expiryDate: expirationDate,
            evaluatorRequirementId: evaluatorRequirementId
        };

        try 
        {
            if (credentialUuid)
            {
                let response = await axios.put(process.env.REACT_APP_BASE_API_URL + '/credential/update/' + credentialUuid, 
                    credential, { withCredentials: true } );
                
                setMessage(response.data.message);
            }
            else
            {
                let response = await axios.post(process.env.REACT_APP_BASE_API_URL + '/credential/create', credential, { withCredentials: true } );
                setCredentialUuid(response.data.uuid);
                createdNewCredential.current = true;
                setMessage(response.data.message);
                setTitle("Edit Credential Requirement");
                setUpdateButtonText("Update Credential Requirement");
            }
            setAlertVariant("primary");  
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger"); 
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        } 
        finally 
        {
            window.scrollTo(0, 0);
            setUpdateButtonClicked(false);
        }
    };

    let handleCreateVersion = async (e) => {
        e.preventDefault();
        setVersionButtonClicked(true);
        const version = {
            name: versionName,
            deprecationDate: versionDate,
            credentialUuid: credentialUuid
        };

        try 
        {
            setVersionButtonClicked(true);
            let response = await axios.post(process.env.REACT_APP_BASE_API_URL + '/credential/version/create', version, { withCredentials: true } );
            let versionsResult = await axios.get(process.env.REACT_APP_BASE_API_URL + '/credential/versions/' + credentialUuid, 
                { withCredentials: true } );
            setVersionName("");
            setVersions(versionsResult.data);
            setMessage(response.data.message);
            setAlertVariant("primary");  
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger"); 
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        } 
        finally 
        {
            setShowVersionConfirmation(false);
            setVersionButtonClicked(false);
            window.scrollTo(0, 0);
        }
    };

    let handleDelete = async (e) => {
        e.preventDefault();
        setDeleteButtonClicked(true);
        try 
        {
            let response = await axios.delete(process.env.REACT_APP_BASE_API_URL + '/credential/delete/' + credentialUuid, 
                { withCredentials: true } );
            setName("");
            setDescription("");
            setDisplayActions(false);
            setShowDeleteConfirmation(false);
            setCredentialUuid("");
            setDisplayForm(false);
            //setTitle("Create Credential");
            //setUpdateButtonText("Create credential");
            deleted.current = true;
            setMessage(response.data.message);
            setAlertVariant("primary");  
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger"); 
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        } 
        finally 
        {
            showDeleteConfirmation(false);
            setDeleteButtonClicked(false);
            window.scrollTo(0, 0);
        }
    };

	return (
		<HelmetProvider>
            <Helmet>
                    <title>{ process.env.REACT_APP_APP_NAME } - Credential</title>
            </Helmet>
            
            <div className="p-2">
                { credentialUuid &&
                    <h6><a href={"/organization?organizationUuid=" + organization.uuid }>{organization.name}</a>{' > '}
                    <a href={"/credential/list?organizationUuid=" + organization.uuid }>Credentials</a>{' > '}
                    Edit</h6>
                }
                { !credentialUuid &&
                    <h6><a href={"/organization?organizationUuid=" + organization.uuid }>{organization.name}</a>{' > '}
                    <a href={"/credential/list?organizationUuid=" + organization.uuid }>Credentials</a>{' > '}
                    Create</h6>
                }
            </div>
            
            <div className="d-flex align-items-center justify-content-center p-3">
				<div className="Outer">
					<table>
						<tbody>
							<tr>
								<td className="d-flex align-items-center justify-content-center">
									<h3>{title}</h3>
								</td>
							</tr>
							<tr>
								<td className="d-flex align-items-center justify-content-center Outer">    
									{message ? <Alert key="messageAlert" variant={alertVariant}>{message}</Alert> : null}
								</td>
							</tr>
                            { displayForm &&
                                <tr>
                                    <td className='d-flex align-items-center justify-content-center'>
                                        <Form onSubmit={handleUpdate} className='Form Margin-Bottom'>
                                            <Form.Group className="mb-3" controlId="formName">
                                                <Form.Label>Name *</Form.Label>
                                                <Form.Control type="text" placeholder="Enter Requirement Name"
                                                    value={name}
                                                    name="name" 
                                                    onChange={(e) => setName(e.target.value)} 
                                                    required />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formDescription">
                                                <Form.Label>Description</Form.Label>
                                                <Form.Control as="textarea" placeholder="Enter Description"
                                                    rows={3}
                                                    value={description}
                                                    name="description" 
                                                    onChange={(e) => setDescription(e.target.value)} />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formType">
                                                <Form.Label>Type (certification, license, etc.) *</Form.Label>
                                                <Form.Control type="text" placeholder="Enter Type"
                                                    value={type}
                                                    name="type" 
                                                    onChange={(e) => setType(e.target.value)}
                                                    required />
                                            </Form.Group>
                                            <CredentialExpirationTypeSelect 
                                                credentialExpirationTypeId={credentialExpirationTypeId}
                                                setCredentialExpirationTypeId={setCredentialExpirationTypeId} />
                                            <DateOfCompletion
                                                credentialExpirationTypeId={credentialExpirationTypeId}
                                                expirationDays={expirationDays}
                                                setExpirationDays={setExpirationDays} />
                                            <DefinedExpiration
                                                credentialExpirationTypeId={credentialExpirationTypeId}
                                                expirationDate={expirationDate}
                                                setExpirationDate={setExpirationDate} />
                                            <EvaluatorRequirementSelect
                                                evaluatorRequirementId={evaluatorRequirementId}
                                                setEvaluatorRequirementId={setEvaluatorRequirementId} 
                                                setEvaluatorRequirementDescription={setEvaluatorRequirementDescription} />
                                            { evaluatorRequirementDescription &&
                                                <p><i>{evaluatorRequirementDescription}</i></p>
                                            }
                                            <NaicsSelect 
                                                naicsCodeId={naicsCodeId}
                                                setNaicsCodeId={setNaicsCodeId} />
                                            <Form.Group className="mb-3" controlId="formPublic">
												<Form.Label>Make this credential public? <i>This will allow other organizations to use this 
                                                    credential as a requirement. Once a requirement is made public it cannot be made private.</i> *</Form.Label>
												<Form.Check type="switch" 
													name="public" 
													onChange={publicHandler}
													checked={publicOrg} />
											</Form.Group>
                                            <p>
                                                { '* Required' }
                                            </p>
                                            <Button variant="primary" type="submit" hidden={updateButtonClicked}>
                                                {updateButtonText}
                                            </Button>
                                            <Button variant="primary" disabled hidden={!updateButtonClicked}>
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                Loading...
                                            </Button>
                                            { credentialUuid &&
                                                <Button as="a" href={"/credential/requirement?credentialUuid=" + credentialUuid} style={{marginLeft: "10px"}}>Credential Requirements</Button>
                                            }
                                        </Form>
                                    </td>
                                </tr>
                            }
                            { displayForm && displayActions &&
                                <>
                                    <tr>
                                        <td>
                                            <div className="Form Margin-Bottom">
                                                <h5>Versions</h5>
                                                <Form onSubmit={handleCreateVersion}>
                                                    <Form.Group className="mb-3" controlId="formVersionName">
                                                        <Form.Label>Name *</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter Version Name"
                                                            value={versionName}
                                                            name="versionName" 
                                                            onChange={(e) => setVersionName(e.target.value)} 
                                                            required />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formVersionDate">
                                                        <Form.Label>Deprecation Date *</Form.Label>
                                                        <Form.Control type="date" 
                                                            defaultValue={versionDate}
                                                            name="versionDate" 
                                                            onChange={(e) => setVersionDate(e.target.value)} 
                                                            required />
                                                    </Form.Group>
                                                </Form>
                                                <p>
                                                    { '* Required' }
                                                </p>
                                                <Button variant="primary" type="submit" onClick={handleShowVersionConfirmation} hidden={versionButtonClicked} className="Margin-Bottom">
                                                    Publish Version
                                                </Button>
                                                <Button variant="primary" disabled hidden={!versionButtonClicked} className="Margin-Bottom">
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                    Loading...
                                                </Button>
                                                <Modal show={showVersionConfirmation} onHide={handleCloseVersionConfirmation}>
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>Confirm Credential Version</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>Are you certain you want to publish a version of this credential? It cannot be deleted once it is published.</Modal.Body>
                                                    <Modal.Footer>
                                                    <Button variant="secondary" onClick={handleCloseVersionConfirmation}>
                                                        Cancel
                                                    </Button>
                                                    <Button variant="danger" onClick={handleCreateVersion}>
                                                        Publish Version
                                                    </Button>
                                                    </Modal.Footer>
                                                </Modal>
                                                
                                                { versions.length < 1 &&
                                                    <p>This credential has no published versions.</p>
                                                }
                                                {versions.map((version) => {
                                                    return (
                                                        <div key={version.uuid}>
                                                            <a href={"/credential/version?organizationUuid=" + organization.uuid + 
                                                                "&credentialVersionUuid=" + version.uuid}>{version.versionName} ({formatDate(version.deprecationDate)})</a>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="Form">
                                                Caution, this cannot be undone!<br />
                                                <Button variant="danger" onClick={handleShowDeleteConfirmation} type="submit" hidden={deleteButtonClicked}>
                                                    Delete Credential
                                                </Button>
                                                <Button variant="danger" disabled hidden={!deleteButtonClicked}>
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                    Deleting...
                                                </Button>
                                                <Modal show={showDeleteConfirmation} onHide={handleCloseDeleteConfirmation}>
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>Confirm Credential Deletion</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>Are you certain you want to delete the credential ({name})? This cannot be undone!</Modal.Body>
                                                    <Modal.Footer>
                                                    <Button variant="secondary" onClick={handleCloseDeleteConfirmation}>
                                                        Cancel
                                                    </Button>
                                                    <Button variant="danger" onClick={handleDelete}>
                                                        Delete Credential
                                                    </Button>
                                                    </Modal.Footer>
                                                </Modal>
                                            </div>
                                        </td>
                                    </tr>
                                </>
							}
						</tbody>
					</table>
				</div>
			</div>
        </HelmetProvider>
	);
};

export default EditCredential;
