import React, { useState, useEffect, useRef } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal';

const OrganizationDetails = () => {

    const [alertVariant, setAlertVariant] = useState("danger");
    const [message, setMessage] = useState("");
    const [organization, setOrganization] = useState("");
    const [admin, setAdmin] = useState(false);
    const [owner, setOwner] = useState(false);
    const [pendingAffiliationRequests, setPendingAffiliationRequests] = useState([]);
    const [activeAffiliations, setActiveAffiliations] = useState([]);
    const [update, setUpdate] = useState(false);
    const [showAffiliationSelfDeleteConfirmation, setShowSelfDeleteAffiliationConfirmation] = useState(false);
    const [detailsOnly, setDetailsOnly] = useState(true);

    const organizationUuid = useRef();
    const affiliationUuid = useRef();
    const deleteSelfAffiliation = useRef(false);
    const deleteAffiliationButtonText = useRef("Remove Affiliation");
    
    
    const handleCloseSelfDeleteAffiliationConfirmation = () => setShowSelfDeleteAffiliationConfirmation(false);
  	const handleShowSelfDeleteAffiliationConfirmation = () => setShowSelfDeleteAffiliationConfirmation(true);

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search);
        organizationUuid.current = queryParameters.get("organizationUuid");
        fetchData();
    }, [update]);

    const fetchData = async () => {
        try
        {
            let orgResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/organization/" + organizationUuid.current, { withCredentials: true });
            setOrganization(orgResponse.data);
            let adminResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/organization/admin/" + organizationUuid.current, { withCredentials: true });
            setAdmin(adminResponse.data.property);
            let ownerResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/organization/owner/" + organizationUuid.current, { withCredentials: true });
            setOwner(ownerResponse.data.property);
            if (adminResponse.data.property)
            {
                let pendingAffiliationRequestsResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/organization/affiliations/pending/" + organizationUuid.current, { withCredentials: true });
                setPendingAffiliationRequests(pendingAffiliationRequestsResponse.data);
                let activeAffiliations = await axios.get(process.env.REACT_APP_BASE_API_URL + "/organization/affiliations/active/" + organizationUuid.current, { withCredentials: true });
                setActiveAffiliations(activeAffiliations.data);
            }
            else if(!deleteSelfAffiliation.current)
            {
                let userDetailResponse = await axios.get(process.env.REACT_APP_BASE_AUTH_API_URL + "/details", { withCredentials: true });
                let affiliationResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/organization/affiliation/" + organizationUuid.current, 
                    {
                        params: {
                            email: userDetailResponse.data.email
                        },
                        withCredentials: true
                    });
                affiliationUuid.current = affiliationResponse.data.uuid;
                setDetailsOnly(false);
                if (affiliationResponse.data.confirmed === "9999-12-31T23:59:59.9999999")
                {
                    deleteAffiliationButtonText.current = "Remove Affiliation Request";
                }
            }
            setUpdate(false);
        }
        catch (error)
        {
            if (error.response.status === 404)
            {
                return
            }
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        }
    };

    function formatDate(timestampString) 
    {
        const options = { year: "numeric", month: "long", day: "numeric"}
        return new Date(timestampString).toLocaleDateString(undefined, options)
    }

    let handleAffiliationRequestApprove = async (e, requestUuid) => {
        e.preventDefault();
		try
        {
            let response = await axios.put(process.env.REACT_APP_BASE_API_URL + "/organization/affiliation/confirm/" + requestUuid, null, { withCredentials: true });
            setAlertVariant("primary");
            setMessage(response.data.message);
            setUpdate(true);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
            window.scrollTo(0, 0);
        }
	}

    let handleAffiliationDelete = async (e, requestUuid, selfDelete) => {
        e.preventDefault();
		try
        {
            let response = await axios.delete(process.env.REACT_APP_BASE_API_URL + "/organization/affiliation/delete/" + requestUuid, { withCredentials: true });
            deleteSelfAffiliation.current = true;
            setAlertVariant("primary");
            setMessage(response.data.message);
            affiliationUuid.current = "";
            setUpdate(true);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
            window.scrollTo(0, 0);
        }
	}

    let handleRequestAffiliation = async (e) => {
        e.preventDefault();
		try
        {
            let response = await axios.post(process.env.REACT_APP_BASE_API_URL + "/organization/affiliation/request/" + organizationUuid.current, null,
                { withCredentials: true });
            setAlertVariant("primary");
            setMessage(response.data.message);
            setUpdate(true);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        }
        finally
        {
            window.scrollTo(0, 0);
        }
	}

	return (
		<HelmetProvider>
            <Helmet>
                    <title>{ process.env.REACT_APP_APP_NAME } - Organization</title>
            </Helmet>
            <div className="p-2">
                <h6>{organization.name}</h6>
            </div>
            <div className="p-3 Center-Outer">
                
                <div className="List Outer">
                    <h3 className='Center-Text Margin-Bottom'>Organization</h3>
                    {message ? <Alert key="messageAlert" variant={alertVariant}>{message}</Alert> : null}
                    <div className="Details Margin-Bottom">
                        <table>
                            <tbody>
                                <tr>
                                    <td>Name:</td>
                                    <td>{organization.name}</td>
                                </tr>
                                { organization.description &&
                                    <tr>
                                        <td>Description:</td>
                                        <td>
                                            <div style={{ whiteSpace: 'pre-wrap' }}>
                                                {organization.description}
                                            </div>
                                        </td>
                                    </tr>
                                }
                                <tr>
                                    <td>Telephone Number:</td>
                                    <td>{organization.telephoneNumber}</td>
                                </tr>
                                <tr>
                                    <td>Address Line 1:</td>
                                    <td>{organization.addressLine1}</td>
                                </tr>
                                { organization.addressLine2 && 
                                    <tr>
                                        <td>Address Line 2:</td>
                                        <td>{organization.addressLine2}</td>
                                    </tr>
                                }
                                <tr>
                                    <td>City:</td>
                                    <td>{organization.city}</td>
                                </tr>
                                <tr>
                                    <td>State/Province:</td>
                                    <td>{organization.stateProvince}</td>
                                </tr>

                                <tr>
                                    <td>Postal Code:</td>
                                    <td>{organization.postalCode}</td>
                                </tr>
                                <tr>
                                    <td>Country:</td>
                                    <td>{organization.country}</td>
                                </tr>
                                <tr>
                                    <td>Approval Required to Affiliate:</td>
                                    <td>{organization.affiliationApprovalRequired ? "Yes" : "No"}</td>
                                </tr>
                                <tr>
                                    <td>
                                        { owner &&
                                            
                                            <Button as="a" href={"/organization/edit?organizationUuid=" + organizationUuid.current} 
                                                style={{ marginRight: "10px" }}>
                                                Edit
                                            </Button>
                                                
                                        }
                                        { admin &&
                                            <>
                                                <Button as="a" href={"/credential/list?organizationUuid=" + organizationUuid.current} 
                                                    style={{ marginRight: "10px" }}>
                                                    Credentials
                                                </Button>
                                                <Button as="a" href={"/requirement/list?organizationUuid=" + organizationUuid.current} 
                                                    style={{ marginRight: "10px" }}>
                                                    Requirements
                                                </Button>
                                            </>
                                        }
                                    </td>
                                    <td></td>
                                </tr>
                            </tbody> 
                        </table>
                    </div>
                    { admin &&  
                        <div>
                            <div className="List Margin-Bottom">
                                <h4 className="Center-Text Margin-Bottom">Pending Affiliation Requests</h4>
                                { pendingAffiliationRequests.map((pending) => {
                                    return(
                                        <div className="list-group" key={pending.uuid}>
                                            <div className="list-group-item list-group-item-action flex-column align-items-start">
                                                <div className="d-flex w-100 justify-content-between">
                                                    <h5 className="mb-1">{pending.user.surname}, {pending.user.givenName}</h5>
                                                    <small>Requested: {formatDate(pending.requested)}</small>
                                                </div>
                                                <p className="mb-1">Registered: {formatDate(pending.user.registered)}</p>
                                                <p className="mb-1">E-mail: {pending.user.email}</p>
                                                { pending.user.organization &&
                                                    <p className="mb-1">Organization: {pending.user.organization}</p>
                                                }
                                                <p className="mb-1">{pending.user.addressLine1}</p>
                                                { pending.user.addressLine2 &&
                                                    <p className="mb-1">{pending.user.addressLine2}</p>
                                                }
                                                <p className="mb-1">{pending.user.city}, {pending.user.stateProvince} {pending.user.postalCode}</p>
                                                <p className="mb-1">{pending.user.country}</p>
                                                <p className="mb-1">Telephone: {pending.user.telephoneNumber}</p>
                                                <Button variant="success" onClick={(e) => handleAffiliationRequestApprove(e, pending.uuid)}
                                                    style={{marginRight: "10px"}} type="submit">
                                                    Approve
                                                </Button>
                                                <Button variant="danger" onClick={(e) => handleAffiliationDelete(e, pending.uuid, false)} type="submit">
                                                    Deny
                                                </Button>
                                            </div>
                                        </div>
                                    )
                                })}
                                { pendingAffiliationRequests.length < 1 &&
                                    <p className="Center-Text">There are no pending affiliation requests.</p>
                                }
                            </div>
                            <div className="List">
                                <h4 className="Center-Text Margin-Bottom">Active Affiliations</h4>
                                { activeAffiliations.map((active) => {
                                    return(
                                        <div className="list-group" key={active.uuid}>
                                            <div className="list-group-item list-group-item-action flex-column align-items-start">
                                                <div className="d-flex w-100 justify-content-between">
                                                    <h5 className="mb-1">{active.user.surname}, {active.user.givenName}</h5>
                                                    <small>Approved: {formatDate(active.confirmed)}</small>
                                                </div>
                                                <p className="mb-1">Registered: {formatDate(active.user.registered)}</p>
                                                <p className="mb-1">E-mail: {active.user.email}</p>
                                                { active.user.organization &&
                                                    <p className="mb-1">Organization: {active.user.organization}</p>
                                                }
                                                <p className="mb-1">{active.user.addressLine1}</p>
                                                { active.user.addressLine2 &&
                                                    <p className="mb-1">{active.user.addressLine2}</p>
                                                }
                                                <p className="mb-1">{active.user.city}, {active.user.stateProvince} {active.user.postalCode}</p>
                                                <p className="mb-1">{active.user.country}</p>
                                                <p className="mb-1">Telephone: {active.user.telephoneNumber}</p>
                                                <Button variant="danger" onClick={(e) => handleAffiliationDelete(e, active.uuid, false)} type="submit">
                                                    Remove
                                                </Button>
                                            </div>
                                        </div>
                                    )
                                })}
                                { activeAffiliations.length < 1 &&
                                    <p className="Center-Text">There are no active affiliations.</p>
                                }
                            </div>
                        </div>
                    }
                    { !admin && !deleteSelfAffiliation.current && !detailsOnly &&
                        <div className="Details Center-Outer Margin-Bottom">
                            <Button variant="danger" onClick={handleShowSelfDeleteAffiliationConfirmation} type="submit">
                                {deleteAffiliationButtonText.current}
                            </Button>
                            <Modal show={showAffiliationSelfDeleteConfirmation} onHide={handleCloseSelfDeleteAffiliationConfirmation}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Confirm Affiliation Removal</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>Are you certain you want to remove this affiliation?</Modal.Body>
                                <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseSelfDeleteAffiliationConfirmation}>
                                    Cancel
                                </Button>
                                <Button variant="danger" onClick={(e) => handleAffiliationDelete(e, affiliationUuid.current, true)}>
                                    {deleteAffiliationButtonText.current}
                                </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    }
                    { !admin && !affiliationUuid.current &&
                        <div className="Details Center-Outer">
                            <Button variant="primary" onClick={(e) => handleRequestAffiliation(e)} type="submit">
                                Request Affiliation
                            </Button>
                        </div>
                    }
                </div> 
            </div>     
        </HelmetProvider>
	);
};

export default OrganizationDetails;
