import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Form } from "react-bootstrap";
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner';
import './RequirementStyle.css'

const SystemRequirement = ({setUpdate, setAlertVariant, setMessage, credential}) => {

    const [buttonClicked, setButtonClicked] = useState(false);
    const [categories, setCategories] = useState([]);
    const [requirements, setRequirements] = useState([]);
    const [categoryId, setCategoryId] = useState(0);
    const [requirementId, setRequirementId] = useState(0);

    useEffect(() => {
		fetchData();
	}, []);

    const fetchData = async () => {
        try
        {
            let result = await axios.get(process.env.REACT_APP_BASE_API_URL + '/credential/requirement/system/categories', { withCredentials: true } );
            const categoriesArray = [];
            result.data.forEach((value) => {
                let key = value.name;
                if (value.description)
                {
                    key = key + " - " + value.description;
                }
                categoriesArray.push({
                    key: key,
                    value: value.id,
                });
            });
            setCategories([{key: 'Select Category', value: ''}, ...categoriesArray]);
        }
        catch (error)
        {
            console.log(error);
        }
    };

    let handleCategorySelect = async (value) => {
        try
        {
            setRequirements([]);
            setCategoryId(value);
            let result = await axios.get(process.env.REACT_APP_BASE_API_URL + '/credential/requirement/system/' + value, 
                { withCredentials: true } );
            const requirementsArray = [];
            result.data.forEach((value) => {
                let key = value.name;
                if (value.description)
                {
                    key = key + " - " + value.description;
                }
                requirementsArray.push({
                    key: key,
                    value: value.id,
                });
            });
            setRequirements([{key: 'Select Requirement', value: ''}, ...requirementsArray]);
            console.log(requirements)
        }
        catch (error)
        {
            console.log(error)
        }
    };

    let handleAdd = async (e) => {
        e.preventDefault();
        try
        {
            let credentialRequirement = {
                isSystemRequirement: true,
                SystemRequirementId: requirementId
            };
            setButtonClicked(true);
            let response = await axios.post(process.env.REACT_APP_BASE_API_URL + "/credential/credentialrequirement/create/" + credential.uuid, 
                credentialRequirement, { withCredentials: true});
            setAlertVariant("primary");
            setMessage(response.data.message);
            console.log(response)
            setUpdate(true);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        }
        finally
        {
            setButtonClicked(false);
            window.scrollTo(0, 0);
        }
    };

	return (
        <div className="d-flex align-items-center justify-content-center">
            <div className="Outer">
                <Form onSubmit={handleAdd} className='Form'>
                    <div className="Center-Text Margin-Bottom"><h5>System Requirements</h5></div>
                    <h6>Organizations are encouraged to select from system-level requirements whenever possible to ensure searchability and cross-compatibility within the Proof Peak network.</h6>
                    <Form.Control as="select"
                        value={categoryId}
                        name="category" 
                        onChange={(e) => handleCategorySelect(e.target.value) }
                        className="drop-down-list Margin-Bottom"
                        required>
                        {categories.map((option) => {
                            return (
                                <option key={option.value} value={option.value}>
                                    {option.key}
                                </option>
                            );
                        })}
                    </Form.Control>
                    <Form.Control as="select"
                        value={requirementId}
                        name="requirement" 
                        onChange={(e) => setRequirementId(e.target.value)} 
                        className="drop-down-list"
                        required>
                        {requirements.map((option) => {
                            return (
                                <option key={option.value} value={option.value}>
                                    {option.key}
                                </option>
                            );
                        })}
                    </Form.Control>
                    <Button variant="primary" type="submit" hidden={buttonClicked} style={{marginTop: "15px"}}>
                        Add System Requirement
                    </Button>
                    <Button variant="primary" disabled hidden={!buttonClicked} style={{marginTop: "15px"}}>
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        Loading...
                    </Button>
                </Form>
            </div>
        </div>
	);
};

export default SystemRequirement;
