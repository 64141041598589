import { useState } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import ReactCountryDropdown from "react-country-dropdown";
import axios from 'axios';
import TelephoneNumber from "../../TelephoneNumber";

const FooterLinks = () => {
    return (
        <>
            <a href="/login" className="link-primary">Login</a>
            {"\u00A0|\u00A0"}
            <a href="/passwordreset" className="link-primary">Forgot Password</a>
            {"\u00A0|\u00A0"}
            <a href="/register/resend" className="link-primary">Resend Registration Email</a>
        </>
    );
}

const Register = () => {

    const [username, setUsername] = useState("");
    const [givenName, setGivenName] = useState("");
    const [surname, setSurname] = useState("");
    const [telephoneNumber, setTelephoneNumber] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [addressLine2, setAddressLine2] = useState("");
    const [organization, setOrganization] = useState("");
    const [city, setCity] = useState("");
    const [stateProvince, setStateProvince] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [country, setCountry] = useState("US");
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const [message, setMessage] = useState("");
    const [buttonClicked, setButtonClicked] = useState(false);
    const [alertVariant, setAlertVariant] = useState("danger");
    const [displayForm, setDisplayForm] = useState(true);

    let handleSubmit = async (e) => {
        e.preventDefault();

        if (password1 !== password2)
        {
            setMessage("The passwords do not match.");
            return;
        }

        setButtonClicked(true);

        const userInfo = {
            username: username,
            password: password1,
            givenName: givenName,
            surname: surname,
            organization: organization,
            telephoneNumber: telephoneNumber,
            addressLine1: addressLine1,
            addressLine2: addressLine2,
            city: city,
            stateProvince: stateProvince,
            postalCode: postalCode,
            country: country
        };

        try 
        {
            let response = await axios.post(process.env.REACT_APP_BASE_AUTH_API_URL + '/register', userInfo );
            setUsername("");
            setGivenName("");
            setSurname("");
            setOrganization("");
            setAddressLine1("");
            setAddressLine2("");
            setCity("");
            setStateProvince("");
            setPostalCode("");
            setTelephoneNumber("");
            setPassword1("");
            setPassword2("");
            setDisplayForm(false);
            setAlertVariant("primary");               
            setMessage(response.data.message);
        }
        catch (error)
        {
            console.log(error)
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        } 
        finally 
        {
            window.scrollTo(0, 0);
            setButtonClicked(false);
        }
    };

    if (displayForm) {
        return (
            <HelmetProvider>
                <Helmet>
                    <title>{ process.env.REACT_APP_APP_NAME } - Register</title>
                </Helmet>
                <div className="d-flex align-items-center justify-content-center p-3">
                    <div className="Outer">
                        <table>
                            <tbody>
                                <tr>
                                    <td className="d-flex align-items-center justify-content-center">
                                        <h3>Register</h3>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="d-flex align-items-center justify-content-center Outer">    
                                        {message ? <Alert key="messageAlert" variant={alertVariant}>{message}</Alert> : null}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="d-flex align-items-center justify-content-center">
                                                <Form onSubmit={handleSubmit} className='Form'>
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Form.Label>Username (email address) *</Form.Label>
                                                        <Form.Control type="email" placeholder="Enter Username"
                                                        value={username}
                                                        name="username" 
                                                        onChange={(e) => setUsername(e.target.value)} 
                                                        required />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formGivenName">
                                                        <Form.Label>First Name *</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter First Name"
                                                        value={givenName}
                                                        name="givenName" 
                                                        onChange={(e) => setGivenName(e.target.value)} 
                                                        required />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formSurname">
                                                        <Form.Label>Last Name *</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter Last Name"
                                                        value={surname}
                                                        name="surname" 
                                                        onChange={(e) => setSurname(e.target.value)} 
                                                        required />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formOrganization">
                                                        <Form.Label>Organization</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter Organization"
                                                        value={organization}
                                                        name="organization" 
                                                        onChange={(e) => setOrganization(e.target.value)} />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formAddressLine1">
                                                        <Form.Label>Address Line 1 *</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter Address Line 1"
                                                        value={addressLine1}
                                                        name="addressLine1" 
                                                        onChange={(e) => setAddressLine1(e.target.value)} 
                                                        required />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formAddressLine2">
                                                        <Form.Label>Address Line 2</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter Address Line 2"
                                                        value={addressLine2}
                                                        name="addressLine2" 
                                                        onChange={(e) => setAddressLine2(e.target.value)} />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formCity">
                                                        <Form.Label>City *</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter City"
                                                        value={city}
                                                        name="city" 
                                                        onChange={(e) => setCity(e.target.value)} 
                                                        required />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formStateProvince">
                                                        <Form.Label>State/Province *</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter State/Province"
                                                        value={stateProvince}
                                                        name="stateProvince" 
                                                        onChange={(e) => setStateProvince(e.target.value)} 
                                                        required />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formPostalCode">
                                                        <Form.Label>Postal Code *</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter Postal Code"
                                                        value={postalCode}
                                                        name="postalCode" 
                                                        onChange={(e) => setPostalCode(e.target.value)} 
                                                        required />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formCountry">
                                                        <Form.Label>Country *</Form.Label><br />
                                                        <ReactCountryDropdown
                                                        defaultCountry="US"
                                                        onSelect={(e) => setCountry(e.name)}
                                                        required />
                                                    </Form.Group>
                                                    <TelephoneNumber telephoneNumber={telephoneNumber} setTelephoneNumber={setTelephoneNumber} />
                                                    <Form.Group className="mb-3" controlId="formPassword1">
                                                        <Form.Label>Password *</Form.Label>
                                                        <Form.Control type="password" placeholder="Enter Password"
                                                        value={password1}
                                                        name="password1" 
                                                        onChange={(e) => setPassword1(e.target.value)} 
                                                        required />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formPassword2">
                                                        <Form.Label>Re-Enter Password *</Form.Label>
                                                        <Form.Control type="password" placeholder="Re-Enter Password"
                                                        value={password2}
                                                        name="password2" 
                                                        onChange={(e) => setPassword2(e.target.value)} 
                                                        required />
                                                    </Form.Group>
                                                    <p>
                                                        { '* Required' }
                                                    </p>
                                                    <Button variant="primary" type="submit" hidden={buttonClicked}>
                                                        Register
                                                    </Button>
                                                    <Button variant="primary" disabled hidden={!buttonClicked}>
                                                        <Spinner
                                                            as="span"
                                                            animation="grow"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                        Loading...
                                                    </Button>
                                                </Form>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="d-flex align-items-center justify-content-center">
                                        { FooterLinks() }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>       
            </HelmetProvider>
        );
    }
    else {
        return (
            <HelmetProvider>
                <Helmet>
                    <title>{ process.env.REACT_APP_APP_NAME } - Register</title>
                </Helmet>
                <div className="d-flex align-items-center justify-content-center p-3">
                <table>
                    <tbody>
                        <tr>
                            <td className="d-flex align-items-center justify-content-center Outer">    
                                {message ? <Alert key="messageAlert" variant={alertVariant}>{message}</Alert> : null}
                            </td>
                        </tr>
                        <tr>
                            <td className="d-flex align-items-center justify-content-center">
                                { FooterLinks() }
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
            </HelmetProvider>
        );
    }
}

export default Register;