import React, { useState, useEffect, useRef } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';
import { Button } from "react-bootstrap";

import SearchBar from '../../SearchBar/SearchBar';
import SearchResultsList from '../../SearchBar/SearchResultsList';

const Organization = () => {

    const userInfo = useRef();
    const [organizationAdministrators, setOrganizationAdministrators] = useState([]);
    const [organizationOwners, setOrganizationOwners] = useState([]);
    const [organizationAffiliations, setOrganizationAffiliations] = useState([]);
    const [alertVariant, setAlertVariant] = useState("danger");
    const [message, setMessage] = useState("");
    const [searchResults, setSearchResults] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try
        {
            let userDataResponse = await axios.get(process.env.REACT_APP_BASE_AUTH_API_URL + "/details", { withCredentials: true });
            userInfo.current = userDataResponse.data;
            let organizationsResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/organization/user/" + userInfo.current.userId, { withCredentials: true })
            setOrganizationAffiliations(organizationsResponse.data.affiliation);
            setOrganizationOwners(organizationsResponse.data.owner);
            setOrganizationAdministrators(organizationsResponse.data.administrator);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        }
    };

	return (
		<HelmetProvider>
            <Helmet>
                    <title>{ process.env.REACT_APP_APP_NAME } - Organizations</title>
            </Helmet>
            <div className="p-3 Center-Outer">
                <div className="List Outer">
                    <h3 className="Center-Text Margin-Bottom">Organizations</h3>
                    {message ? <Alert key="messageAlert" variant={alertVariant}>{message}</Alert> : null}
                    <div className="Margin-Bottom">
                        <SearchBar 
                            setSearchResults={setSearchResults}
                            apiEndpoint="/organization/search" 
                            searchBoxPlaceholder="Type to search all organizations..." />
                        <SearchResultsList results={searchResults} urlPrefix="/organization?organizationUuid=" />
                    </div>
                    <div className="Margin-Bottom">
                        <Button as="a" href="/organization/edit">Create Organization</Button>
                    </div>
                    <h5 className="Margin-Bottom">Current Affiliations</h5>
                    { organizationOwners.map((org) => {
                        return(
                            <div className="list-group" key={org.uuid + '-owner'}>
                                <a href={"/organization?organizationUuid=" + org.uuid} className="list-group-item list-group-item-action flex-column align-items-start">
                                    <div className="d-flex w-100 justify-content-between">
                                        <h5 className="mb-1">{org.name}</h5>
                                        <small>Owner</small>
                                        </div>
                                    <div style={{ whiteSpace: 'pre-wrap' }}>
                                        <p className="mb-1">{org.description}</p>
                                    </div>
                                </a>
                            </div>
                        )
                    })}
                    { organizationAdministrators.map((org) => {
                        if (!organizationOwners.some(e => e.uuid === org.uuid))
                        {
                            return(
                                <div className="list-group" key={org.uuid + '-owner'}>
                                    <a href={"/organization?organizationUuid=" + org.uuid} className="list-group-item list-group-item-action flex-column align-items-start">
                                        <div className="d-flex w-100 justify-content-between">
                                        <h5 className="mb-1">{org.name}</h5>
                                        <small>Administrator</small>
                                        </div>
                                        <div style={{ whiteSpace: 'pre-wrap' }}>
                                            <p className="mb-1">{org.description}</p>
                                        </div>
                                    </a>
                                </div>
                            )
                        }
                        else
                        {
                            return null
                        }
                    })}
                    { organizationAffiliations.map((org) => {
                        if (!organizationOwners.some(e => e.uuid === org.uuid) && !organizationAdministrators.some(e => e.uuid === org.uuid))
                        {
                            return(
                                <div className="list-group" key={org.uuid + '-affiliation'}>
                                    <a href={"/organization?organizationUuid=" + org.uuid}className="list-group-item list-group-item-action flex-column align-items-start">
                                        <div className="d-flex w-100 justify-content-between">
                                        <h5 className="mb-1">{org.name}</h5>
                                        <small>Affiliation</small>
                                        </div>
                                        <div style={{ whiteSpace: 'pre-wrap' }}>
                                            <p className="mb-1">{org.description}</p>
                                        </div>
                                    </a>
                                </div>
                            )
                        }
                        else
                        {
                            return null
                        }
                    })}
                    { organizationAffiliations.length < 1 &&
                        <p className="Center-Text">You are not affiliated with any organizations.</p>
                    }
                </div> 
            </div>     
        </HelmetProvider>
	);
};

export default Organization;
