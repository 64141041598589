import { useState, useEffect, useRef } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';
import Button from 'react-bootstrap/Button'
import OrganizationRequirement from "./OrganizationRequirement";

import SystemRequirement from "./SystemRequirement";

const CredentialRequirement = () => {

    const [alertVariant, setAlertVariant] = useState("danger");
    const [message, setMessage] = useState("");
    const [organization, setOrganization] = useState({});
    const [credential, setCredential] = useState({});
    const [update, setUpdate] = useState(false);

    const credentialUuid = useRef("");

    useEffect(() => {
        fetchData();
        setUpdate(false);
    }, [update]);

    const fetchData = async () => {
        try
        {
            const queryParameters = new URLSearchParams(window.location.search);
		    credentialUuid.current = queryParameters.get("credentialUuid");
            let credentialResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/credential/" + credentialUuid.current, 
                { withCredentials: true, params: { includeRequirements: true } });
            setCredential(credentialResponse.data);
            let organizationResponse = await axios.get(process.env.REACT_APP_BASE_API_URL + "/organization/" + credentialResponse.data.organizationUuid, 
                { withCredentials: true });
            setOrganization(organizationResponse.data);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        }
    };

    let handleRemoveRequirement = async (e, credentialRequirementUuid) => {
        e.preventDefault();
        try
        {
            let response = await axios.delete(process.env.REACT_APP_BASE_API_URL + "/credential/credentialrequirement/delete/" + credentialRequirementUuid, 
                { withCredentials: true});
            setAlertVariant("primary");
            setMessage(response.data.message);
            setUpdate(true);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        }
        finally
        {
            window.scrollTo(0, 0);
        }
    };

	return (
		<HelmetProvider>
            <Helmet>
                    <title>{ process.env.REACT_APP_APP_NAME } - Credential Requirements</title>
            </Helmet>
            <div className="p-2">
                <h6><a href={"/organization?organizationUuid=" + organization.uuid}>{organization.name}</a>
                {' > '}<a href={"/credential/list?organizationUuid=" + organization.uuid}>Credentials</a>
                {' > '}<a href={"/credential/edit?organizationUuid=" + organization.uuid + "&credentialUuid=" + credential.uuid}>{credential.name}</a>
                {' > '}Requirements</h6>
            </div>
            <div className="p-3 Center-Outer">
                <div className="List Outer">
                    <h3 className="Center-Text">Credential Requirements</h3>
                    {message ? <Alert key="messageAlert" variant={alertVariant}>{message}</Alert> : null}
                    <div className="Details Margin-Bottom">
                        <div className="Center-Text">
                            <h5>{credential.name}</h5>
                        </div>
                        { credential.description &&
                            <div style={{ whiteSpace: 'pre-wrap' }}>
                                {credential.description}
                            </div>
                        }
                    </div>
                    <SystemRequirement 
                        setUpdate={setUpdate} 
                        setAlertVariant={setAlertVariant}
                        setMessage={setMessage}
                        credential={credential}
                    />
                    <OrganizationRequirement 
                        setUpdate={setUpdate} 
                        setAlertVariant={setAlertVariant}
                        setMessage={setMessage}
                        credential={credential}
                    />
                    <h5 className="Margin-Bottom">Requirements</h5>
                    { credential.requirements && credential.requirements.map((requirement) => {
                            return(
                                <div className="list-group" key={requirement.credentialRequirementUuid}>
                                    <div className="list-group-item list-group-item-action flex-column align-items-start">
                                        <div className="d-flex w-100 justify-content-between">
                                            <h5 className="mb-1">{requirement.name}</h5>
                                            <small>
                                                {requirement.isSystemRequirement ? "System Requirement " : "Organization Requirement "}
                                                ({requirement.requirementType.name})
                                            </small>
                                        </div>
                                        {requirement.description &&
                                            <div style={{ whiteSpace: 'pre-wrap' }}>
                                                <p className="mb-1">{requirement.description}</p>
                                            </div>
                                        }
                                        <Button variant="danger" onClick={(e) => handleRemoveRequirement(e, requirement.credentialRequirementUuid, false)} type="submit">
                                            Remove
                                        </Button>
                                    </div>
                                </div>
                            )
                    })}
                    { credential.requirements && credential.requirements.length < 1 &&
                        <p className="Center-Text">This credential has no requirements.</p>
                    } 
                </div> 
            </div> 
        </HelmetProvider>
	);
}

export default CredentialRequirement;