import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import { Form } from "react-bootstrap";
import "./CredentialOptionSelect.css";

const EvaluatorRequirementSelect = ({evaluatorRequirementId, setEvaluatorRequirementId, setEvaluatorRequirementDescription}) => {

    const [options, setOptions] = useState([]);
    
    const resultData = useRef({});

    useEffect(() => {
		fetchData();
	}, []);

    const fetchData = async (value) => {
        try
        {
            let result = await axios.get(process.env.REACT_APP_BASE_API_URL + '/credential/evaluator/requirements', { withCredentials: true } );
            resultData.current = result.data;
            const array = [];
            result.data.forEach((value) => {
                array.push({
                    key: value.name,
                    value: value.id,
                });
            });
            setOptions([{key: 'Select Evaluator Requirement', value: ''}, ...array]);
        }
        catch (error)
        {
            console.log(error);
        }
    };

    const handleSelect = async (id) => {
        let requirement = resultData.current.find((requirement) => requirement.id === Number(id));
        setEvaluatorRequirementDescription(requirement.description);
    };

	return (
        <Form.Group className="mb-3" controlId="formEvaluator">
            <Form.Label>Evaluator Requirement *</Form.Label>
            <Form.Control as="select"
                value={evaluatorRequirementId}
                name="evaluatorRequirement" 
                onChange={(e) => {setEvaluatorRequirementId(e.target.value); handleSelect(e.target.value)}} 
                className="credential-option-list"
                required>
                {options.map((option) => {
                    return (
                        <option key={option.value} value={option.value}>
                        {option.key}
                        </option>
                    );
                })}
            </Form.Control>
        </Form.Group>
	);
};

export default EvaluatorRequirementSelect;
