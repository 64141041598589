import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Form } from "react-bootstrap";
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner';
import './RequirementStyle.css'

const OrganizationRequirement = ({setUpdate, setAlertVariant, setMessage, credential}) => {

    const [buttonClicked, setButtonClicked] = useState(false);
    const [requirements, setRequirements] = useState([]);
    const [requirementUuid, setRequirementUuid] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try
            {
                if (credential.organizationUuid)
                {
                    let result = await axios.get(process.env.REACT_APP_BASE_API_URL + '/credential/organization/requirement/' + credential.organizationUuid, 
                        { withCredentials: true } );
                    const requirementsArray = [];
                    result.data.forEach((value) => {
                        let key = value.name;
                        if (value.description)
                        {
                            key = key + " - " + value.description;
                        }
                        requirementsArray.push({
                            key: key,
                            value: value.requirementUuid,
                        });
                    });
                    setRequirements([{key: 'Select Requirement', value: ''}, ...requirementsArray]);
                }
            }
            catch (error)
            {
                console.log(error);
            }
        };
		fetchData();
	}, [credential.organizationUuid]);

    let handleAdd = async (e) => {
        e.preventDefault();
        try
        {
            setButtonClicked(true);
            let credentialRequirement = {
                isSystemRequirement: false,
                organizationRequirementUuid: requirementUuid
            };
            let response = await axios.post(process.env.REACT_APP_BASE_API_URL + "/credential/credentialrequirement/create/" + credential.uuid, 
                credentialRequirement, { withCredentials: true});
            setAlertVariant("primary");
            setMessage(response.data.message);
            setUpdate(true);
        }
        catch (error)
        {
            console.log(error)
            setAlertVariant("danger");
            if ((error.response.data.message !== undefined) && (error.response.data.message.length !== 0)) {
                setMessage(error.response.data.message)
            } else {
                setMessage("Unknown Error");
            }
        }
        finally
        {
            setButtonClicked(false);
            window.scrollTo(0, 0);
        }
    };

	return (
        <div className="d-flex align-items-center justify-content-center p-3">
            <div className="Outer">
                <Form onSubmit={handleAdd} className='Form'>
                    <div className="Center-Text Margin-Bottom"><h5>Organization Requirements</h5></div>
                    <Form.Control as="select"
                        value={requirementUuid}
                        name="requirement" 
                        onChange={(e) => setRequirementUuid(e.target.value)} 
                        className="drop-down-list"
                        required>
                        {requirements.map((option) => {
                            return (
                                <option key={option.value} value={option.value}>
                                    {option.key}
                                </option>
                            );
                        })}
                    </Form.Control>
                    <Button variant="primary" type="submit" hidden={buttonClicked} style={{marginTop: "15px"}}>
                        Add Organization Requirement
                    </Button>
                    <Button variant="primary" disabled hidden={!buttonClicked} style={{marginTop: "15px"}}>
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        Loading...
                    </Button>
                </Form>
            </div>
        </div>
	);
};

export default OrganizationRequirement;
