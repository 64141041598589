import { Outlet } from "react-router-dom";
import NavigationBar from "../NavigationBar";

const AppLayout = () => {
    return <div>
            <NavigationBar />
            <Outlet />
        </div>;
};

export default AppLayout;
